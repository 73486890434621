import React from 'react';
import { Link } from 'gatsby';
import { useMediaQuery } from '@mantine/hooks';

import vidaBannerMobile from '@/assets/images/banner_vidaa_responsive.png';
import vidaBanner from '@/assets/images/vidaa_banner.png';
import vidaaLogo from '@/assets/images/VIDAA.png';

const VidaBanner = () => {
  const matches = useMediaQuery('(max-width: 1024px)');

  return (
    <div
      className="h-[528px] bg-[#01050E] bg-center bg-no-repeat pt-14 lg:h-[496px] lg:py-24"
      style={{ backgroundImage: `url(${matches ? vidaBannerMobile : vidaBanner})` }}
    >
      <div className="container">
        <div className="row">
          <div className="lg:col-12">
            <div className="lg:max-w-[416px]">
              <img src={vidaaLogo} alt="VIDAA" className="mb-6 h-10 lg:mb-10 lg:h-[70px]" />
              <p className="mb-4 font-light text-white lg:mb-8">
                Los televisores Hisense con sistema operativo VIDAA ofrecen un acceso instantáneo y personalizado a tus
                series y aplicaciones favoritas como Movistar +, Netflix, Prime Video, YouTube y muchas más…
              </p>
              <Link className="my-3 flex w-fit items-center gap-2 font-bold text-white" to="/vidaa">
                Conoce más
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VidaBanner;
