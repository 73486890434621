import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Layout } from "@/layout/Layout";
import Seo from "../components/common/Seo";
import ProductCard from "../components/common/ProductCard";
import { MobileSelector, DesktopSelector } from "../components/common/Selectors";
import VidaaBanner from "../components/common/VidaaBanner.jsx";

import TvTypeIcon from "@/assets/icons/tvType.inline.svg";
import TvSizeIcon from "@/assets/icons/tvSizeGreen.inline.svg";

const WHITE = "white";

const TvPage = ({ pageContext, location }) => {
  const { slug, banner_text, banner_text_color, banner_image, banner_mobile_image, products } = pageContext.category;

  const subcategories = Array.from(new Set(products.map((p) => p.subcategory.name))).map((option) => ({
    name: option,
    value: option,
  }));

  const sizeOptions = [
    { name: "32’’ - 43’’", value: "32,43" },
    { name: "50’’ - 58’’", value: "50,58" },
    { name: "59’’ - 75’’", value: "59,75" },
  ];

  const defatulSubcategory = subcategories.some((subcategory) => subcategory.value === location?.state?.subcategory)
    ? location?.state?.subcategory
    : "";

  const [selectedSubcategory, setSelectedSubcategory] = useState(defatulSubcategory);
  const productsByCategory = selectedSubcategory
    ? products.filter((i) => i.subcategory.name === selectedSubcategory)
    : products;

  const [sizeRange, setSizeRange] = useState("");

  const filteredProducts = sizeRange
    ? productsByCategory.filter((i) => {
        const sizeRangeArray = sizeRange.split(",");
        return sizeRangeArray[0] <= i.size && i.size <= sizeRangeArray[1];
      })
    : productsByCategory;

  return (
    <Layout pageId="tv" isHeaderTransparent={true} headerWhiteText={banner_text_color === WHITE}>
      <section className="relative">
        <GatsbyImage
          image={getImage(banner_mobile_image.imageFile)}
          alt={banner_mobile_image.title}
          className="h-auto w-full md:!hidden"
        />
        <GatsbyImage
          image={getImage(banner_image.imageFile)}
          alt={banner_image.title}
          className="!hidden h-auto w-full object-cover md:!block"
        />
        {banner_text && (
          <div
            className={`
              absolute left-0 top-1/2 z-10 w-full -translate-y-1/2 text-center [&>*]:mx-auto
              [&>h1]:text-[32px] [&>h1]:font-bold [&>h1]:leading-10 [&>h1]:md:text-6xl
              [&>p]:md:font-bold [&>p]:text-sm [&>p]:md:text-xl [&>p]:mt-3 [&>p]:md:mt-6 [&>p]:max-w-[50%]
              ${banner_text_color === WHITE ? "[&>*]:text-white" : "[&>*]:text-[#303030]"}
            `}
            dangerouslySetInnerHTML={{ __html: banner_text }}
          />
        )}
      </section>

      {/* Mobile Filters */}
      <div className="grid h-12 grid-cols-2 font-medium lg:hidden">
        <MobileSelector
          name={"Tipo"}
          selectedOption={selectedSubcategory}
          setSelectedOption={setSelectedSubcategory}
          options={subcategories}
        />
        <MobileSelector
          name={"Tamaño"}
          selectedOption={sizeRange}
          setSelectedOption={setSizeRange}
          options={sizeOptions}
        />
      </div>

      {/* Desktop Filters */}
      <div className="hidden py-12 lg:block">
        <div className="container flex justify-center divide-x divide-[#ECEFF1] text-[#697276]">
          <DesktopSelector
            name={"Tipo:"}
            icon={<TvTypeIcon />}
            selectedOption={selectedSubcategory}
            setSelectedOption={setSelectedSubcategory}
            options={subcategories}
          />
          <DesktopSelector
            name={"Tamaño:"}
            icon={<TvSizeIcon />}
            selectedOption={sizeRange}
            setSelectedOption={setSizeRange}
            options={sizeOptions}
          />
          <div>
            <button
              type="button"
              onClick={() => {
                setSelectedSubcategory("");
                setSizeRange("");
              }}
              className="ml-6 text-[#00AAA6]"
            >
              Limpiar
            </button>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="pb-14 md:pb-20">
        <div className="container">
          <div className="row">
            {filteredProducts.map((product) => (
              <div key={product.name} className="md:col-6 lg:col-4">
                <ProductCard
                  name={product.name}
                  image={product.sliders[0]}
                  link={`/${slug}/${product.slug}`}
                  features={product.features_icons}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <VidaaBanner />
    </Layout>
  );
};

export const Head = ({ pageContext }) => <Seo title={`${pageContext.category.name} | Hisense`} />;

export default TvPage;
